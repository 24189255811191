<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'processCrud'"
          crudName="Processos"
          newText="Novo"
          selectedName="Enviar Processos"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="true"
          :enableReset="false"
          :dataForm="dataForm"
          @checkeds="checkeds"
        >
        </base-crud>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modalUsers" tabindex="-1" role="dialog" aria-labelledby="modalUsersLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalUsersLabel">Selecione o usuário que receberá os processos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                        <label for="Name">
                          <span class="required">*</span> Responsável:
                        </label>
                        
                        <v-select :options="users" :reduce="(users) => users.id" :label="'name'"
                          v-model="process.responsible_id" class="vselect" 
                        />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" @click="saveResponsible">Salvar Alterações</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "usersTable",
      showMessageImport: false,
      processCrud: {},
      process: {},
      users: [],
      pages: [],
      url: "",
      columns: ['checkbox', "id", "number", "clippings", 'responsible_id', 'status', "actions"],
      tableData: ["id", "number", "clippings", 'responsible_id', 'status'],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["number", 'responsible_id', "clippings"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          checkbox: "",
          id: "ID",
          number: "Número",
          clippings: "Recortes",
          responsible_id: "Responsável",
          actions: "Ações",
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },   
        listColumns: {
          responsible_id: [],
        },     
        templates: {
          status: function (h, row, index) {
            return row.status == 1
              ? 'Ativo'
              : 'Não Ativo';
          },
          responsible_id: function (h, row, index) {
            return row.responsible ? row.responsible.name
              : 'Não atribuído';
          },
          clippings: function (h, row, index) {
            if(row.pending_clippings == true || row.pending_clippings == 'true'){
              return (
                <h5 class="text-center">
                  {row.clippings.length} <span class="ml-2 badge badge-warning">
                    Pendências
                  </span>
                </h5>
              );
            } else {
              return  (
                <h5 class="text-center"> { row.clippings.length } 
                  <span class="ml-2 badge badge-success">
                    OK
                  </span>
                </h5>
              );
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ['responsible', 'clippings'];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "processes",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },

    query: function (query) {
      let columns = {
        id: "id",
        number: "number",
        responsible_id: "responsible[responsible_id]"
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },

    checkeds(data){      
      $('#modalUsers').modal('show');
      this.process.selecteds = data;
    },

    saveResponsible(){
      const self = this;
      const api = self.$store.state.api + "process/save-in-mass";

      axios
        .post(api, self.process)
        .then((response) => {
          $('#modalUsers').modal('hide');   
          this.$refs.processCrud.$refs.table.refresh()
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data;

          var arr = [];

          self.users.forEach(element => {
            arr.push({id: element.id, text: element.name})  
          });

          self.options.listColumns.responsible_id = arr;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted(){
    this.getUsers();  
    // console.log(this.$refs.processCrud.$refs.table.refresh())
  }
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>