import Crud from '../views/Process/Crud'
import Entity from '../views/Process/Add'
import View from '../views/Process/View'

let routes = {
    path: '/processes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/processes/add',
            name: 'NewProcess',
            component: Entity,
            meta: {
                pageTitle: 'Processos',
                access_nivel: ['1', '2'],
                breadcrumb: [
                    {
                        text: 'Adicionar Processo',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/processes/edit/:id?',
            name: 'EditProcess',
            component: Entity,
            meta: {
                pageTitle: 'Processos',
                access_nivel: ['1', '2'],
                breadcrumb: [
                    {
                        text: 'Editar Processo',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/processes/view/:id?',
            name: 'ViewProcess',
            component: View,
            meta: {
                pageTitle: 'Processos',
                access_nivel: ['1', '2'],
                breadcrumb: [
                    {
                        text: 'Visualizar Processo',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/processes',
            name: 'processes',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Processos',
                access_nivel: ['1', '2'],
                breadcrumb: [
                    {
                        text: 'Processos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
